/*global gtag*/
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { TechGatedForm as GatedForm } from "@components/Form"
import { Button } from "@components/Button"
import { splitText } from "@utils"
import { Lottie } from "@components/Lottie"
import techTalentWhitepaper from "@lottie/tech-talent-whitepaper.json"
import { GeneralForm } from "@components/Form"

const TechTalenWhitepaperGatedPage = ({ data }) => {
  const { aboutJKImage } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-gray-darkest",
    navigationColor: "text-white",
    displayGeneralForm: false,
  })

  const downloadGuide = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "gated_download", { event_category: "download", event_label: "tech_report" })
    window.open("/documents/jk-tech-talent-whitepaper.pdf", "_blank").focus()
  }

  return (
    <Layout>
      <Seo title="Tech Talent Whitepaper Gated" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>

        <div className="container relative lg:gap-4 ttwp-grid">
          <div className="col-start-1 col-end-3 row-start-1 row-end-3 bg-gray-darkest extend before:bg-gray-darkest after:bg-gray-darkest"></div>

          <div className="flex justify-center ttwp-title pt-clamp-14-25 pb-clamp-8-14">
            <div className="w-full lg:w-10/12">
              <h1
                className="text-clamp-40-80"
                data-aos="stagger"
                dangerouslySetInnerHTML={{
                  __html: splitText({
                    "text-teal-light": "Free guide: Winning over former Big Tech employees",
                    "text-white": "to corporate tech roles.",
                  }),
                }}
              />
            </div>
          </div>

          <div className="relative lg:grid lg:grid-cols-6 ttwp-sub-title pb-clamp-16-36">
            <div className="col-span-5 col-start-2" data-aos="fade-right">
              <h2 className="text-white font-body text-clamp-20-35">
                A data-driven guide to attracting and hiring newly available tech talent.
              </h2>
            </div>
          </div>

          <div className="z-10 flex justify-center ttwp-sidebar">
            <div className="w-full lg:w-5/6">
              <div className="relative lg:px-8 xl:px-14">
                <div className="absolute inset-0 flex lg:hidden">
                  <div className="w-full bg-gray-darkest extend before:bg-gray-darkest after:bg-gray-darkest lg:extend-none"></div>
                </div>
                <div className="absolute inset-0 flex">
                  <div className="w-full mt-auto bg-purple-light extend before:bg-purple-light after:bg-purple-light lg:extend-none h-1/2"></div>
                </div>
                <div className="grid grid-cols-8 sm:grid-cols-6 lg:block">
                  <div className="col-span-7 sm:col-span-4 z-1">
                    <div className="shadow-md">
                      <Lottie animation={techTalentWhitepaper} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative w-full pb-20 lg:px-8 xl:px-14 pt-14">
                <div className="absolute inset-0">
                  <div className="w-full h-full bg-purple-light extend before:bg-purple-light after:bg-purple-light lg:extend-none"></div>
                </div>
                <div className="relative">
                  <h3 className="text-26">Share some basic info to access your guide</h3>
                  <div className="mt-10">
                    <GatedForm
                      id="785"
                      subject="Tech Talent Report"
                      recipient="katiek@jkdesign.com, markm@jkdesign.com, chrisb@jkdesign.com"
                      fn={downloadGuide}
                    />
                  </div>
                </div>
                r
              </div>
            </div>
          </div>

          <div className="ttwp-about pt-clamp-12-20 lg:pt-28 pb-28">
            <div data-aos="fade-up" className="grid-cols-6 pr-12 lg:grid">
              <div className="col-span-5 col-start-2">
                <p className="font-bold leading-9 text-25">
                  Making your organization stand out to laid off Big Tech talent isn’t impossible—you just have to tell
                  your very best story.
                </p>
                <div className="mt-14">
                  <p>And our original research shows that you have a great story to tell.</p>
                  <p>
                    This free guide dives into the numbers to reveal what truly matters to recently laid off Big Tech
                    talent. It’s full of actionable insights that you can use to craft a powerful story—one that
                    showcases the advantages your organization has to offer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main>
          <section>
            <div className="lg:container">
              <div className="flex justify-center">
                <div className="flex flex-col-reverse grid-cols-10 gap-x-4 lg:grid lg:w-10/12">
                  <div className="relative col-start-2 col-end-11 row-start-1 row-end-2 lg:mt-28">
                    <div className="w-full h-full bg-teal-light extend after:bg-teal-light"></div>
                  </div>
                  <div className="container relative grid-cols-12 col-span-6 col-start-5 row-start-1 row-end-2 sm:grid lg:block lg:max-w-full lg:px-0 py-clamp-12-19 lg:mt-28">
                    <div className="absolute inset-0 grid grid-cols-12 lg:hidden">
                      <div className="col-span-12 sm:col-span-11 bg-teal-light extend before:bg-teal-light"></div>
                    </div>
                    <div data-aos="fade-right" className="relative col-span-10 lg:pl-18">
                      <h3 className="text-clamp-35-62">About JK.</h3>
                      <p className="mt-clamp-9-16">
                        JK is a women-owned, full-service creative agency with over 30 years of employee engagement
                        experience. We are expert storytellers who help companies of all kinds drive more meaningful
                        engagement with employees and candidates through inspired talent communications and distinctive
                        employer brands.
                      </p>
                      <p>
                        By blending empathy, insight, and a clear understanding of your goals, we deliver programs and
                        platforms that pull people in and help you attract and retain the talent your business needs to
                        be its very best.
                      </p>
                      <Button link="/about-us" className="text-gray-darkest mt-clamp-11-17">
                        Learn more
                      </Button>
                    </div>
                  </div>
                  <div className="container relative col-start-1 col-end-5 row-start-1 row-end-2 pr-0 lg:max-w-full lg:px-0">
                    <div className="absolute inset-0 grid grid-cols-12 lg:hidden">
                      <div className="col-span-12 mt-auto h-1/2 lg:hidden sm:col-span-11 bg-teal-light extend before:bg-teal-light"></div>
                    </div>
                    <div data-aos="fade-left" className="lg:min-w-204 lg:absolute lg:right-0">
                      <GatsbyImage
                        className="relative"
                        objectFit="contain"
                        image={getImage(aboutJKImage)}
                        alt="About JK"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <section className="pt-clamp-32-44">
        <div className="container flex xl:justify-center">
          <div className="xl:w-10/12">
            <div style={{ maxWidth: "365px" }}>
              <h2 data-aos="stagger" className="text-title">
                <span>Let’s</span> <span>chat.</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="container mt-24">
          <div className="mx-auto xl:w-10/12">
            <GeneralForm subject="General Inquiry | JK Tech Talent Gated" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TechTalenWhitepaperGatedPage

export const TechTalenWhitepaperGatedQuery = graphql`
  query techTalenWhitepaperGatedQuery {
    aboutJKImage: file(relativePath: { eq: "global/outside.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
